import APIService from '@/services/api';

// eslint-disable-next-line import/no-cycle
import { SELECTABLE_FEATURE_KEYS, SET_MAP_FEATURE_HOVERED_NS } from '@/store/map';

const namespace = 'supervisions';

const GET_SUPERVISIONS_GEOMETRY = 'GET_SUPERVISIONS_GEOMETRY';
const GET_SUPERVISIONS = 'GET_SUPERVISIONS';
const SET_SUPERVISIONS_COLLECTION = 'SET_SUPERVISIONS_COLLECTION';
const SET_SUPERVISIONS_COLLECTION_LOADING = 'SET_SUPERVISIONS_COLLECTION_LOADING';
const SET_SUPERVISIONS_COLLECTION_LOADED = 'SET_SUPERVISIONS_COLLECTION_LOADED';
const SET_SUPERVISION_HOVER = 'SET_SUPERVISION_HOVER';
const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTERS = 'RESET_FILTERS';
const SET_PAGINATION = 'SET_PAGINATION';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const SET_TABLE_ITEMS = 'SET_TABLE_ITEMS';
const SET_TABLE_ITEMS_LOADING = 'SET_TABLE_ITEMS_LOADING';

export const GET_SUPERVISIONS_NS = `${namespace}/${GET_SUPERVISIONS}`;
export const SET_SUPERVISION_HOVER_NS = `${namespace}/${SET_SUPERVISION_HOVER}`;
export const SET_FILTERS_NS = `${namespace}/${SET_FILTERS}`;
export const RESET_FILTERS_NS = `${namespace}/${RESET_FILTERS}`;
export const SET_PAGINATION_NS = `${namespace}/${SET_PAGINATION}`;

function updateSupervision(state, { supervisionId, properties }) {
  const index = state.supervisionsCollection.findIndex((f) => f.id === supervisionId);
  const feature = { ...state.supervisionsCollection[index] };
  const updatedProperties = { ...feature, ...properties };
  state.supervisionsCollection[index] = updatedProperties;
}

const defaultFilters = {
  dateFrom: '',
  dateTo: '',
  isClosed: 'False',
};

function initialState() {
  return {
    filters: { ...defaultFilters },
    pagination: {
      itemsPerPage: 25,
      page: 1,
      sortBy: ['-identifier'],
      sortDesc: [false],
    },
    supervisionsCollection: [],
    supervisionsCollectionLoading: false,
    supervisionsCollectionLoaded: false,
    itemsLength: 0,
    tableIds: [],
    tableItemsLoading: false,
  };
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    tableItems: (state) => {
      const tableItems = [];
      state.supervisionsCollection.forEach((supervision) => {
        const index = state.tableIds.indexOf(supervision.id);
        if (index !== -1) tableItems[index] = supervision;
      });
      return tableItems;
    },
    mapCenters: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['authentication/isUserSuperviserUssp']) return null;

      const features = state.supervisionsCollection.map((supervision) => {
        const { center: geometry, id, identifier, is_closed: isClosed } = supervision;
        return {
          type: 'Feature',
          id,
          properties: {
            id,
            is_supervision: true,
            display_identifier: identifier,
            is_closed: isClosed,
          },
          geometry,
        };
      });
      return { type: 'FeatureCollection', features };
    },
    mapAreas: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['authentication/isUserSuperviserUssp']) return null;

      const features = state.supervisionsCollection.map((supervision) => {
        const { geometry, id, identifier, is_closed: isClosed } = supervision;
        return {
          type: 'Feature',
          id,
          properties: {
            id,
            is_supervision: true,
            display_identifier: identifier,
            is_closed: isClosed,
            approval_id: id,
          },
          geometry,
        };
      });
      return { type: 'FeatureCollection', features };
    },
    supervisionHover: (state, getters, rootState) => state.supervisionsCollection.find(
      (s) => s.id === rootState.map.featureIdHovered.flight,
    ),
    activeFilters: (state) => {
      let activeFilters = 0;
      const { filters } = state;
      Object.keys(defaultFilters).forEach((key) => {
        if (filters[key] !== defaultFilters[key]) activeFilters += 1;
      });
      return activeFilters;
    },
  },
  mutations: {
    [SET_TABLE_ITEMS_LOADING](state, loading) {
      state.tableItemsLoading = loading;
    },
    [SET_SUPERVISIONS_COLLECTION_LOADING](state, loading) {
      state.supervisionsCollectionLoading = loading;
    },
    [SET_SUPERVISIONS_COLLECTION_LOADED](state) {
      state.supervisionsCollectionLoaded = true;
    },
    [SET_SUPERVISIONS_COLLECTION](state, supervisionsCollection = []) {
      state.supervisionsCollection = supervisionsCollection;
    },
    [SET_FILTERS](state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
    [RESET_FILTERS](state) {
      state.filters = { ...defaultFilters };
    },
    [SET_PAGINATION](state, payload) {
      state.pagination = payload.pagination;
    },
    [UPDATE_PAGINATION](state, { itemsLength, page }) {
      if (itemsLength !== undefined) state.itemsLength = itemsLength;
      if (page !== undefined) state.pagination.page = page;
    },
    [SET_TABLE_ITEMS](state, { items = [] }) {
      items.forEach((item) => {
        updateSupervision(state, { supervisionId: item.id, properties: { ...item } });
      });
      state.tableIds = items.map((i) => i.id);
      state.tableItemsLoading = false;
    },
  },
  actions: {
    async [GET_SUPERVISIONS_GEOMETRY]({ commit, dispatch, getters, state }) {
      commit(SET_SUPERVISIONS_COLLECTION_LOADING, true);
      const { sortBy, sortDesc } = state.pagination;
      const { filters } = state;

      const supervisionParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        ...filters,
      };
      await APIService.getSupervisionsGeometries({ ...supervisionParams })
        .then(({ data }) => {
          commit(SET_SUPERVISIONS_COLLECTION, data.results);
          const { mapAreas } = getters;
          if (mapAreas.features.length) {
            dispatch('map/SET_BOUNDING_BOX', { fromGeom: getters.mapAreas }, { root: true });
          }
          commit(SET_SUPERVISIONS_COLLECTION_LOADED);
        })
        .finally(() => {
          commit(SET_SUPERVISIONS_COLLECTION_LOADING, false);
        });
    },
    async [GET_SUPERVISIONS]({ commit, dispatch, state }, { reloadGeometries = true }) {
      commit(SET_TABLE_ITEMS_LOADING, true);
      if (reloadGeometries || !state.supervisionsCollectionLoaded) {
        await dispatch(GET_SUPERVISIONS_GEOMETRY);
      }
      const { itemsPerPage, sortBy, sortDesc, page } = state.pagination;
      const { filters } = state;

      const supervisionParams = {
        ordering: (sortDesc[0] ? '-' : '') + sortBy[0],
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        ...filters,
      };
      await APIService.getSupervisions({ ...supervisionParams })
        .then(({ data }) => {
          const { count: itemsLength, results: items } = data;
          commit(SET_TABLE_ITEMS, { items });
          commit(UPDATE_PAGINATION, { itemsLength });
        })
        .finally(() => {
          commit(SET_TABLE_ITEMS_LOADING, false);
        });
    },
    [SET_SUPERVISION_HOVER]({ dispatch }, supervisionId) {
      dispatch(
        SET_MAP_FEATURE_HOVERED_NS,
        { featureId: supervisionId, key: SELECTABLE_FEATURE_KEYS.flight },
        { root: true },
      );
    },
    [SET_PAGINATION]({ commit, dispatch }, pagination) {
      commit(SET_PAGINATION, { pagination });
      dispatch(GET_SUPERVISIONS, { reloadGeometries: false });
    },
  },
};
