<template>
  <LayoutListingMap>
    <template
      slot="listing"
      slot-scope="{display, toggleBiggerDisplay}"
    >
      <SupervisionFilters
        :display="display"
        @toggle-filter="toggleBiggerDisplay"
      />
      <SupervisionListing />
    </template>
    <MapboxComponent
      slot="map"
      slot-scope="{display}"
      :display="display"
    />
  </LayoutListingMap>
</template>

<script>
import Map from '@/components/Map/Map.vue';
import SupervisionListing from '@/components/Supervisions/SupervisionListing.vue';
import SupervisionFilters from '@/components/Supervisions/SupervisionFilters.vue';
import LayoutListingMap from '@/layouts/LayoutListingMap.vue';

export default {
  name: 'MapSupervision',
  components: {
    MapboxComponent: Map,
    SupervisionListing,
    SupervisionFilters,
    LayoutListingMap,
  },
};
</script>
