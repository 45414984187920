import Vue from 'vue';
import Router from 'vue-router';

import MapAuthorities from '@/pages/MapAuthorities.vue';
import ApprovalPage from '@/pages/ApprovalPage.vue';
import ActivationPage from '@/pages/ActivationPage.vue';
import MapDronists from '@/pages/MapDronists.vue';
import MapUsers from '@/pages/MapUsers.vue';
import Settings from '@/pages/Settings.vue';
import Default from '@/pages/Default.vue';
import Home from '@/pages/Home.vue';
import Logout from '@/pages/Logout.vue';
import NewPassword from '@/pages/NewPassword.vue';
import TokenConnection from '@/pages/TokenConnection.vue';
import TokenGeneration from '@/pages/TokenGeneration.vue';
import Activity from '@/pages/Activity.vue';
import MapVisitor from '@/pages/MapVisitor.vue';
import MapSupervision from '@/pages/MapSupervision.vue';
import LivestreamPage from '@/pages/LivestreamPage.vue';

import { USER_TYPES, INTERFACE_VERSIONS } from '@/settings';

Vue.use(Router);

export const routes = {
  default: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: false },
    },
    {
      path: '/confirm-reset-password',
      name: 'confirm-reset',
      component: NewPassword,
      meta: { requiresAuth: false },
    },
    {
      path: '/token-connection',
      name: 'token-connection',
      component: TokenConnection,
      meta: { requiresAuth: false },
    },
    {
      path: '/token-generation',
      name: 'token-generation',
      component: TokenGeneration,
      meta: { requiresAuth: false },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
    },
    {
      path: '*',
      name: 'other',
      component: Default,
    },
    {
      path: '/livestream/:name',
      name: 'livestream',
      component: LivestreamPage,
      meta: { requiresAuth: false },
    },
    {
      path: '/livestream-demo',
      name: 'livestream-demo',
      component: LivestreamPage,
      meta: { requiresAuth: false },
    },
  ],
  [USER_TYPES.AUTHORITIES]: [
    {
      path: '/map/flights',
      redirect: '/map',
    },
    {
      path: '/map',
      name: 'map-authoritites',
      component: MapAuthorities,
    },
    {
      path: '/map/approvals',
      name: 'approvals',
      component: MapAuthorities,
    },
    {
      path: '/map/activations',
      name: 'activations',
      component: MapAuthorities,
    },
    {
      path: '/map/layers',
      name: 'layers',
      component: MapAuthorities,
    },
    {
      path: '/map/traces',
      name: 'traces',
      component: MapAuthorities,
    },
  ],
  [USER_TYPES.SUBSCRIBERS]: [
    {
      path: '/map',
      redirect: '/map/flights',
    },
    {
      path: '/map/approvals',
      redirect: '/map/flights',
    },
    {
      path: '/map/flights',
      name: 'flights',
      component: MapDronists,
    },
    {
      path: '/map/drones',
      name: 'drones',
      component: MapDronists,
    },
    {
      path: '/map/pilots',
      name: 'pilots',
      component: MapDronists,
    },
    {
      path: '/activity',
      name: 'activity',
      component: Activity,
    },
    {
      path: '/settings?tabSelected=subscription&state=success',
      name: 'subscription-success',
      component: Settings,
    },
  ],
  [USER_TYPES.EXPLOITANTS]: [
    {
      path: '/map',
      redirect: '/map/flights',
    },
    {
      path: '/map/flights',
      name: 'flights',
      component: MapDronists,
    },
    {
      path: '/map/drones',
      name: 'drones',
      component: MapDronists,
    },
    {
      path: '/map/pilots',
      name: 'pilots',
      component: MapDronists,
    },
    {
      path: '/activity',
      name: 'activity',
      component: Activity,
    },
    {
      path: '/settings?tabSelected=subscription&state=success',
      name: 'subscription-success',
      component: Settings,
    },
  ],
  [USER_TYPES.ADMIN]: [
    {
      path: '/map',
      redirect: '/map/users',
    },
    {
      path: '/map/flights',
      redirect: '/map/users',
    },
    {
      path: '/map/approvals',
      redirect: '/map/users',
    },
    {
      path: '/map/users',
      name: 'users',
      component: MapUsers,
    },
  ],
  [USER_TYPES.AUTHORITY_USSPS]: [
    {
      path: '/map',
      redirect: '/settings',
    },
  ],
  [USER_TYPES.DRONIST_USSPS]: [
    {
      path: '/map',
      redirect: '/map/flights',
    },
    {
      path: '/map/flights',
      name: 'flights',
      component: MapDronists,
    },
    {
      path: '/map/drones',
      name: 'drones',
      component: MapDronists,
    },
    {
      path: '/map/pilots',
      name: 'pilots',
      component: MapDronists,
    },
  ],
  [USER_TYPES.VISITORS]: [
    {
      path: '/map',
      redirect: '/map/opinions',
    },
    {
      path: '/map/opinions',
      name: 'opinions',
      component: MapVisitor,
    },
  ],
  [USER_TYPES.REALTIME_SUPERVISER_USSPS]: [
    {
      path: '/map',
      redirect: '/map/supervisions',
    },
    {
      path: '/map/supervisions',
      name: 'supervisions',
      component: MapSupervision,
    },
  ],
  [INTERFACE_VERSIONS.FULL_SCREEN]: [
    {
      path: '/map/approvals/:id',
      name: 'approval-page',
      component: ApprovalPage,
    },
    {
      path: '/map/activations/:id',
      name: 'activation-page',
      component: ActivationPage,
    },
  ],
};

class ClearanceRouter extends Router {
  constructor() {
    super();
    this.addRoutes(routes.default);
  }

  addUserRoutes(userType, interfaceVersion) {
    if (routes[userType]) {
      this.addRoutes(routes[userType]);
    }
    if (routes[interfaceVersion]) {
      this.addRoutes(routes[interfaceVersion]);
    }
  }

  resetRoutes() {
    const newRouter = new ClearanceRouter();
    newRouter.addRoutes(routes.default);
    this.matcher = newRouter.matcher;
  }
}

export default new ClearanceRouter();
