<template>
  <div class="activation-listing">
    <v-data-table
      ref="datatable"
      :headers="headers"
      :items="activations"
      :loading="loading"
      :server-items-length="itemsLength"
      :options.sync="options"
      single-expand
      :expanded.sync="expanded"
      :hide-default-footer="smallDisplay"
      :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
      :footer-props="{
        'items-per-page-text': texts.itemsPerPage,
        'items-per-page-all-text': texts.itemsAll,
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :loading-text="texts.itemsLoading"
      :no-data-text="texts.itemsEmpty"
      :no-results-text="texts.itemsEmpty"
      item-key="id"
      dark
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          v-if="props && props.item"
          :class="getStatusClass(props)"
          class="cursor-pointer"
          @click="selectItem(props)"
          @mouseenter="idHover = props.item.id"
          @mouseleave="idHover = null"
        >
          <td
            v-if="headers.find(e => e.value == 'status')"
            class="text-center"
          >
            <StatusIcon
              :status="props.item.status"
              :breachedPSAs="props.item.approval.is_above_psa"
              :transparentBackground="props.isExpanded"
              withTooltip
              class="activation-listing__icon"
            />
          </td>
          <td
            v-if="headers.find(e => e.value == 'identifier')"
            class="activation-listing__item-identifier"
          >
            <span v-if="props.item.approval.display_identifier">
              {{ props.item.approval.display_identifier }}
            </span>
            <v-badge
              v-if="numberOfSubApprovals(props) > 1"
              left
              overlap
              class="small-badge ml-3"
            >
              <span
                slot="badge"
                class="d-flex justify-center align-center"
              >
                {{ numberOfSubApprovals(props) }}
              </span>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-icon
                    v-on="on"
                    :color="props.isExpanded ? 'white' : 'info'"
                  >
                    mdi-shape-polygon-plus
                  </v-icon>
                </template>
                <translate :translate-params="{nb: numberOfSubApprovals(props)}">
                  This activations request has %{ nb } areas.
                </translate>
              </v-tooltip>
            </v-badge>
          </td>
          <td
            v-if="headers.find(e => e.value == 'company_name')"
            class="px-1"
          >
            {{ props.item.approval.company_name }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'category_name')"
            class="px-1"
          >
            <v-chip
              v-if="props.item.approval.category_name"
              x-small
              :color="props.isExpanded ? 'white' : 'primary'"
            >
              <span
                class="font-weight-medium"
                :class="props.isExpanded ? 'primary--text' : 'white--text'"
              >
                {{ props.item.approval.category_name }}
              </span>
            </v-chip>
          </td>
          <td
            v-if="headers.find(e => e.value == 'commune')"
            class="px-1"
          >
            {{ props.item.approval.commune }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'date_start')"
            class="px-1"
          >
            {{ props.item.date_start | dateShort }} -
            {{ props.item.date_end | dateShort }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'hour')"
            class="px-1"
          >
            {{ props.item.time_start | hour }} -
            {{ props.item.time_end | hour }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'max_height')"
            class="px-1"
          >
            {{ maxFlyingHeight(props) | meterAndFeet }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'max_altitude')"
            class="px-1"
          >
            {{ maxElevation(props) | meterAndFeet }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'qdr')"
            class="px-1"
          >
            {{ props.item.approval.radial | degree }} -
            {{ props.item.approval.distance_to_structure | mile }}
          </td>
          <td
            v-if="headers.find(e => e.value == 'actions')"
            class="action-expand"
          >
            <!-- Easy actions to change status -->
            <div
              v-if="isAuthorityAdminOrManager && idHover === props.item.id"
              class="text-right action-status__cell"
            >
              <div class="action-status__container">
                <div class="d-flex align-center justify-end">
                  <v-tooltip
                    top
                    key="refused"
                    v-if="props.item.status !== 'refused'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="openDialogStatus(props.item, 'refused')"
                      >
                        <StatusIcon status="refused" />
                      </v-btn>
                    </template>
                    <translate>Refuse the activation.</translate>
                  </v-tooltip>
                  <v-tooltip
                    top
                    key="accepted"
                    v-if="props.item.status !== 'accepted'"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="openDialogStatus(props.item, 'accepted')"
                      >
                        <StatusIcon status="accepted" />
                      </v-btn>
                    </template>
                    <translate>Accept the request.</translate>
                  </v-tooltip>
                  <!-- Update activation period -->
                  <v-tooltip
                    top
                    key="update"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="openDialogUpdate(props.item)"
                      >
                        <v-icon color="primary">mdi-clock</v-icon>
                      </v-btn>
                    </template>
                    <translate>Update activation period.</translate>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <v-btn icon>
              <v-icon
                v-if="!props.isExpanded"
                color="black"
              >
                keyboard_arrow_down
              </v-icon>
              <v-icon
                v-else
                color="white"
              >
                keyboard_arrow_up
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          v-if="item"
          :colspan="headers.length"
          class="pa-0"
        >
          <v-skeleton-loader
            v-if="loadingDetails"
            light
            type="list-item-three-line"
          />
          <Activation
            v-else
            :activation="item"
            :key="`activation-${item.id}`"
            @center-on-area="(area) => $emit('center-on-area', area)"
          />
        </td>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }}
        <span v-translate>de</span>
        {{ items.itemsLength }}
      </template>
    </v-data-table>
    <!-- Dialog to change status -->
    <v-dialog
      v-if="dialogStatus"
      v-model="dialogStatus"
      width="650"
      height="800"
      persistent
    >
      <ChangeActivationStatusForm
        :activation="activationToUpdate"
        :statusForUpdate="statusForUpdate"
        @close-form="closeDialogStatus()"
        withDetails
      />
    </v-dialog>
    <!-- Dialog to update period -->
    <v-dialog
      v-if="dialogUpdate"
      v-model="dialogUpdate"
      width="650"
      persistent
      :retain-focus="false"
    >
      <ChangeActivationPeriod
        :activation="activationToUpdate"
        @close="closeDialogUpdate()"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  SET_ACTIVATION_SELECTED_NS,
  SET_ACTIVATION_HOVER_NS,
  SET_PAGINATION_NS,
} from '@/store/activations';
import { APPLICATION_DISPLAY, SET_DISPLAY_NS } from '@/store/application';
import { SUBMITTED } from '@/store/status';

import makeActivationListingHeaders from '@/components/Activations/ActivationListingHeaders';
import Activation from '@/components/Activations/Activation.vue';
import ChangeActivationPeriod from '@/components/Activations/ChangeActivationPeriod.vue';
import ChangeActivationStatusForm from '@/components/Activations/ChangeActivationStatusForm.vue';
import StatusIcon from '@/components/StatusIcon/StatusIcon.vue';

export default {
  name: 'ActivationListing',
  components: {
    Activation,
    ChangeActivationPeriod,
    ChangeActivationStatusForm,
    StatusIcon,
  },
  data() {
    return {
      dialogStatus: false,
      statusForUpdate: null,
      activationToUpdate: null,
      dialogUpdate: false,
    };
  },
  computed: {
    activeTab() {
      return this.$store.getters['application/activeTab'];
    },
    loadingDetails() {
      return this.$store.state.activations.activationDetailsLoading;
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      if (this.bigDisplay) return this.bigHeaders;
      if (this.smallDisplay) return this.smallHeaders;
      return this.mediumHeaders;
    },
    itemsLength() {
      return this.$store.state.activations.itemsLength;
    },
    activations() {
      return !this.loading ? this.$store.getters['activations/tableItems'] : [];
    },
    loading() {
      return this.$store.state.activations.activationsLoading;
    },
    expanded: {
      get() {
        return [this.$store.getters['activations/activationSelected']];
      },
      set() {
      },
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_ACTIVATION_HOVER_NS, newValue);
      },
    },
    idSelected: {
      get() {
        return this.$store.state.map.featureIdSelected.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_ACTIVATION_SELECTED_NS, newValue);
      },
    },
    options: {
      get() {
        return this.$store.state.activations.pagination;
      },
      set(newValue) {
        if (this.$store.state.activations.activationsLoaded) {
          this.$store.dispatch(SET_PAGINATION_NS, newValue);
        }
      },
    },
    texts() {
      return {
        itemsPerPage: this.$gettext('Lines per page:'),
        itemsAll: this.$gettext('All'),
        itemsLoading: this.$gettext('Loading activations...'),
        itemsEmpty: (
          this.activeFilters > 0
            ? this.$gettext('No activation found for selected filters')
            : this.$gettext('No activation found')
        ),
      };
    },
    activeFilters() {
      return this.$store.getters['activations/activeFilters'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
  },
  watch: {
    display(newValue) {
      if (newValue === 'SMALL') {
        this.idSelected = null;
      }
    },
    activeTab() {
      this.idSelected = null;
    },
  },
  created() {
    this.setHeaders();
  },
  methods: {
    setHeaders() {
      const headers = makeActivationListingHeaders(this.$gettext);
      this.smallHeaders = headers.smallHeaders;
      this.mediumHeaders = headers.mediumHeaders;
      this.bigHeaders = headers.bigHeaders;
    },
    subApprovals(props) {
      return props?.item?.approval?.sub_approvals || [];
    },
    numberOfSubApprovals(props) {
      return this.subApprovals(props).length;
    },
    maxFlyingHeight(props) {
      return (
        this.numberOfSubApprovals(props)
          ? Math.max(...this.subApprovals(props).map((s) => s.height))
          : 0
      );
    },
    maxElevation(props) {
      return (
        this.numberOfSubApprovals(props)
          ? Math.max(...this.subApprovals(props).map((s) => s.elevation))
          : 0
      );
    },
    getStatusClass(props) {
      return {
        selected: props.isExpanded,
        [props?.item?.status || SUBMITTED]: props.isExpanded,
      };
    },
    selectItem(props) {
      if (this.loading) {
        return;
      }
      this.smallDisplay && this.$store.dispatch(SET_DISPLAY_NS, APPLICATION_DISPLAY.MEDIUM);
      if (props.isExpanded === false) {
        this.idSelected = props.item.id;
      } else {
        this.idSelected = null;
      }
    },
    selectActivationFromRoute() {
      if (this.$route.query.idSelected !== undefined) {
        this.idSelected = parseInt(this.$route.query.idSelected, 10);
      }
    },
    openDialogStatus(activation, status) {
      this.activationToUpdate = activation;
      this.statusForUpdate = status;
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
      this.statusForUpdate = null;
      this.activationToUpdate = null;
    },
    openDialogUpdate(activation) {
      this.activationToUpdate = activation;
      this.dialogUpdate = true;
    },
    closeDialogUpdate() {
      this.dialogUpdate = false;
      this.activationToUpdate = null;
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.activation-listing ::v-deep .v-data-table__wrapper>table> {
  tbody>tr,
  tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
    &.selected {
      color: $color-white;
      background-color: $color-primary;
      &.accepted {
        background-color: $accepted-background-color;
      }
      &.incomplete {
        background-color: $incomplete-background-color;
      }
      &.refused {
        background-color: $refused-background-color;
      }
      &.submitted {
        background-color: $submitted-background-color;
      }
      &.reserves {
        background-color: $reserves-background-color;
      }
      &.pending {
        background-color: $pending-background-color;
      }
    }
  }
  tbody>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
  tbody>tr:hover:not(.highlighted):not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
  tbody>tr>td, thead>tr>th {
    &.activation-listing__item-identifier {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
}

.activation-listing__icon {
  padding: 10px;
}

.badge_automatic_process {
  color: black;
  font-weight: bold;
}

.action-status {
  &__cell {
    min-width: 32px;
    position: relative;
  }
  &__container {
    position: absolute;
    top: 0;
    right: 32px;
    display: flex;
    align-items: center;
  }
}

</style>
