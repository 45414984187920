<template>
  <div>
    <!-- Filters button -->
    <div
      v-if="mediumDisplay"
      class="supervision-filters d-flex align-center justify-space-between"
    >
      <span>
        {{ texts.itemsLength }}
      </span>
      <v-btn
        text
        small
        dark
        pa-0
        @click="toggleFilter()"
      >
        <span class="text-decoration-underline">
          {{ texts.filters }}
        </span>
        <span
          v-if="activeFilters"
          class="ml-1"
        >
          ({{ activeFilters }})
        </span>
      </v-btn>
    </div>
    <!-- Filters big display -->
    <div
      v-if="bigDisplay"
      class="d-flex"
    >
      <!-- Date from -->
      <div class="supervision-filters flex-grow-1">
        <v-menu
          ref="menuFrom"
          :close-on-content-click="true"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFrom"
              :label="labels.start"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            scrollable
            @input="$refs.menuFrom.save(dateFrom)"
          />
        </v-menu>
      </div>
      <!-- Date to -->
      <div class="supervision-filters flex-grow-1">
        <v-menu
          ref="menuTo"
          :close-on-content-click="true"
          :return-value.sync="dateTo"
          transition="scale-transition"
          content-class="white text-center"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateTo"
              :label="labels.end"
              append-icon="arrow_drop_down"
              dark
              readonly
              v-on="on"
              hide-details
              class="small-dense__input"
            />
          </template>
          <v-date-picker
            v-model="dateTo"
            no-title
            scrollable
            @input="$refs.menuTo.save(dateTo)"
          />
        </v-menu>
      </div>
      <!-- With archives -->
      <div class="supervision-filters">
        <v-switch
          v-model="withArchives"
          color="primary"
          :label="labels.withArchives"
          class="small-dense__switch"
          dark
          hide-details
        />
      </div>
      <!-- Cancel filters -->
      <div class="supervision-filters flex-grow-1 d-flex justify-end">
        <v-btn
          text
          small
          dark
          @click="resetFilters()"
        >
          <span class="text-decoration-underline">
            {{ texts.cancel }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/services/api.helper';

import { display } from '@/settings';

import { GET_SUPERVISIONS_NS, RESET_FILTERS_NS, SET_FILTERS_NS } from '@/store/supervisions';

export default {
  name: 'SupervisionFilters',
  props: { display: String },
  computed: {
    texts() {
      return {
        itemsLength: this.$gettextInterpolate(
          this.$ngettext(
            '%{ nb } approval',
            '%{ nb } approvals',
            this.itemsLength,
          ),
          { nb: this.itemsLength },
        ),
        filters: this.$gettext('Filters'),
        cancel: this.$gettext('Cancel filters'),
      };
    },
    labels() {
      return {
        start: this.$gettext('Start'),
        end: this.$gettext('End'),
        withArchives: this.$gettext('Include archives'),
      };
    },
    bigDisplay() {
      return this.display === display.BIG;
    },
    mediumDisplay() {
      return this.display === display.MEDIUM;
    },
    activeFilters() {
      return this.$store.getters['supervisions/activeFilters'];
    },
    itemsLength() {
      return this.$store.state.supervisions.itemsLength;
    },
    dateFrom: {
      get() {
        return this.$store.state.supervisions.filters.dateFrom;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { dateFrom: newValue });
      },
    },
    dateTo: {
      get() {
        return this.$store.state.supervisions.filters.dateTo;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { dateTo: newValue });
      },
    },
    withArchives: {
      get() {
        return this.$store.state.supervisions.filters.isClosed === null;
      },
      set(newValue) {
        this.$store.commit(SET_FILTERS_NS, { isClosed: newValue === true ? null : 'False' });
      },
    },
  },
  watch: {
    dateFrom() {
      this.debouncedUpdateResult({ that: this });
    },
    dateTo() {
      this.debouncedUpdateResult({ that: this });
    },
    withArchives() {
      this.debouncedUpdateResult({ that: this });
    },
  },
  methods: {
    debouncedUpdateResult: debounce(
      ({ that }) => that.updateResult(),
      1000, // debounce for 1s
    ),
    async updateResult() {
      await this.$store.dispatch(GET_SUPERVISIONS_NS, {});
    },
    resetFilters() {
      this.$store.commit(RESET_FILTERS_NS);
    },
    toggleFilter() {
      this.$emit('toggle-filter');
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.supervision-filters {
  margin: 24px 24px 4px 8px;
}

</style>
