import * as turf from '@turf/turf';
import Vue from 'vue';

import { APPLICATION_TAB } from '@/store/application';
import { SELECTABLE_FEATURE_KEYS } from '@/store/map';

const emptySourceData = { type: 'FeatureCollection', features: [] };
const mapDataWatcherPlugin = (store) => {
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['flights/mapCenters']],
    ([isMapDataLoaded, flightCenters]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserDronist']) {
        store.dispatch('map/SET_FLIGHT_CENTERS', flightCenters, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['flights/mapAreas']],
    ([isMapDataLoaded, flightAreas]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserDronist']) {
        store.dispatch('map/SET_FLIGHT_AREAS', flightAreas, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['approvals/mapCenters']],
    ([isMapDataLoaded, approvalCenters]) => {
      if (
        isMapDataLoaded
        && store.getters['authentication/isUserAuthority']
        && store.getters['application/activeTab'] !== APPLICATION_TAB.ACTIVATION
      ) {
        store.dispatch('map/SET_FLIGHT_CENTERS', approvalCenters, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['approvals/mapAreas']],
    ([isMapDataLoaded, approvalAreas]) => {
      if (
        isMapDataLoaded
        && store.getters['authentication/isUserAuthority']
        && store.getters['application/activeTab'] !== APPLICATION_TAB.ACTIVATION
      ) {
        store.dispatch('map/SET_FLIGHT_AREAS', approvalAreas, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['activations/mapCenters']],
    ([isMapDataLoaded, activationsCenters]) => {
      if (
        isMapDataLoaded
        && store.getters['authentication/isUserAuthority']
        && store.getters['application/activeTab'] === APPLICATION_TAB.ACTIVATION
      ) {
        store.dispatch('map/SET_FLIGHT_CENTERS', activationsCenters, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['activations/mapAreas']],
    ([isMapDataLoaded, activationsAreas]) => {
      if (
        isMapDataLoaded
        && store.getters['authentication/isUserAuthority']
        && store.getters['application/activeTab'] === APPLICATION_TAB.ACTIVATION
      ) {
        store.dispatch('map/SET_FLIGHT_AREAS', activationsAreas, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['opinions/mapCenters']],
    ([isMapDataLoaded, opinionCenters]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserVisitor']) {
        store.dispatch('map/SET_FLIGHT_CENTERS', opinionCenters, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['opinions/mapAreas']],
    ([isMapDataLoaded, opinionAreas]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserVisitor']) {
        store.dispatch('map/SET_FLIGHT_AREAS', opinionAreas, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['supervisions/mapCenters']],
    ([isMapDataLoaded, supervisionCenters]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserSuperviserUssp']) {
        store.dispatch('map/SET_FLIGHT_CENTERS', supervisionCenters, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => [state.map.isMapDataLoaded, getters['supervisions/mapAreas']],
    ([isMapDataLoaded, supervisionAreas]) => {
      if (isMapDataLoaded && store.getters['authentication/isUserSuperviserUssp']) {
        store.dispatch('map/SET_FLIGHT_AREAS', supervisionAreas, { root: true });
      }
    },
  );
  store.watch(
    (state, getters) => getters['supervisions/supervisionHover'],
    (newValue) => {
      store.dispatch('map/SET_APPROVAL_HOVER', { approvalId: newValue?.id }, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['flights/flightSelected'],
    (newValue) => {
      store.dispatch(
        'map/SET_FLIGHT_SELECTED',
        { flightId: newValue?.id },
        { root: true },
      );
    },
  );
  store.watch(
    (state, getters) => getters['flights/flightHover'],
    (newValue) => {
      store.dispatch(
        'map/SET_FLIGHT_HOVER',
        { flightId: newValue ? newValue.id : undefined },
        { root: true },
      );
    },
  );
  store.watch(
    (state, getters) => getters['approvals/approvalHover'],
    (newValue) => {
      store.dispatch(
        'map/SET_APPROVAL_HOVER',
        { approvalId: newValue ? newValue.id : undefined },
        { root: true },
      );
    },
  );
  store.watch(
    (state, getters) => getters['approvals/idHover'],
    (newValue) => {
      store.dispatch('map/SET_APPROVAL_HOVER', newValue, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['flights/idHover'],
    (newValue) => {
      store.dispatch('map/SET_FLIGHT_HOVER', newValue, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['approvals/approvalArea'],
    (approvalArea) => {
      if (!store.getters['authentication/isAuthorityFullScreenInterface']) {
        store.dispatch(
          'map/SET_APPROVAL_SELECTED',
          approvalArea,
          { root: true },
        );
      }
    },
  );
  store.watch(
    (state, getters) => getters['opinions/opinionArea'],
    (opinionArea) => {
      store.dispatch(
        'map/SET_OPINION_SELECTED',
        opinionArea,
        { root: true },
      );
    },
  );
  store.watch(
    (state) => state.cotations.cotations,
    (newValue) => {
      store.dispatch('map/SET_COTATIONS', newValue, { root: true });
    },
  );
  store.watch(
    () => store.getters['flights/constraintHover'],
    (newValue) => {
      let featureCollection = emptySourceData;
      if (newValue?.geometry?.features?.length) {
        featureCollection = turf.featureCollection(newValue.geometry.features.flat());
      }
      store.dispatch('map/SET_CONSTRAINT_HOVER', featureCollection, { root: true });
    },
  );
  store.watch(
    () => store.getters['flights/constraintsToDisplay'],
    (newValue) => {
      let featureCollection = emptySourceData;
      if (newValue.length) {
        featureCollection = turf.featureCollection(
          newValue.map((c) => c.geometry.features.flat()).flat(),
        );
      }
      store.dispatch('map/SET_CONSTRAINT_SELECTED', featureCollection, { root: true });
    },
  );
  store.watch(
    () => store.getters['flights/notamsToDisplay'],
    (newValue) => {
      let featureCollection = emptySourceData;
      if (newValue.length) {
        featureCollection = turf.featureCollection(newValue.flat());
      }
      store.dispatch('map/SET_NOTAM_SELECTED', featureCollection, { root: true });
    },
  );
  store.watch(
    (state) => [
      state.map.isMapDataLoaded, state.structures.items, state.structures.loadingStructures,
    ],
    () => {
      if (
        store.state.map.isMapDataLoaded
        && !store.state.structures.loadingStructures
        && store.getters['authentication/isUserDronist']
      ) {
        store.dispatch(
          'map/SET_CTR_AREAS',
          store.state.structures.items,
          { root: true },
        );
      }
    },
  );
  store.watch(
    (state) => [
      state.map.isMapDataLoaded,
      state.structures.externalStructuresGeometries,
      state.structures.loadingExternalStructuresGeometries,
    ],
    () => {
      if (
        Object.keys(store.state.structures.externalStructuresGeometries).length
        && store.state.map.isMapDataLoaded
        && !store.state.structures.loadingExternalStructuresGeometries
        && store.getters['authentication/isUserDronist']
      ) {
        store.dispatch(
          'map/SET_CTR_AREAS_ONLY_FOR_SUBSCRIBERS',
          store.state.structures.externalStructuresGeometries,
          { root: true },
        );
      }
    },
  );
  store.watch(
    (state) => state.application.display,
    () => {
      Vue.nextTick(() => store.dispatch('map/RESIZE_MAP'));
    },
  );
  store.watch(
    (state) => state.application.loadedKml,
    (loadedKml) => {
      store.dispatch('map/SET_LOADED_KML', loadedKml);
    },
  );
  store.watch(
    (state) => state.flights.idSelected || state.approvals.idSelected,
    (idSelected) => {
      store.dispatch('map/SET_MAP_FEATURE_SELECTED', {
        featureId: idSelected,
        key: SELECTABLE_FEATURE_KEYS.flight,
      });
    },
  );
  store.watch(
    (state) => state.flights.flightApprovalIdSelected,
    (idSelected) => {
      if (idSelected) {
        store.dispatch('map/HIDE_FLIGHTS');
      } else {
        store.dispatch('map/SHOW_FLIGHTS');
        if (store.getters['flights/flightSelected']) {
          store.dispatch(
            'map/SET_FLIGHT_SELECTED',
            { flightId: store.getters['flights/flightSelected'].id },
            { root: true },
          );
        }
      }
    },
  );
  store.watch(
    (state, getters) => getters['flights/mapApprovalAreas'],
    (approvalAreas) => {
      store.dispatch('map/SET_APPROVAL_FLIGHT_SELECTED', approvalAreas, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['application/activeTab'],
    (newActiveTab, oldActiveTab) => {
      if (store.getters['authentication/isUserAuthority']) {
        if (newActiveTab === APPLICATION_TAB.ACTIVATION) {
          store.dispatch('map/SET_FLIGHT_CENTERS', store.getters['activations/mapCenters'], { root: true });
          store.dispatch('map/SET_FLIGHT_AREAS', store.getters['activations/mapAreas'], { root: true });
        } else if (oldActiveTab === APPLICATION_TAB.ACTIVATION) {
          store.dispatch('map/SET_FLIGHT_CENTERS', store.getters['approvals/mapCenters'], { root: true });
          store.dispatch('map/SET_FLIGHT_AREAS', store.getters['approvals/mapAreas'], { root: true });
        }
      }
    },
  );
  store.watch(
    (state, getters) => getters['activations/activationHover'],
    (newValue) => {
      store.dispatch(
        'map/SET_APPROVAL_HOVER',
        { approvalId: newValue?.id },
        { root: true },
      );
    },
  );
  store.watch(
    (state, getters) => getters['activations/activationSelected'],
    () => {
      if (store.getters['application/activeTab'] === APPLICATION_TAB.ACTIVATION) {
        store.dispatch('map/SET_FLIGHT_CENTERS', store.getters['activations/mapCenters'], { root: true });
        store.dispatch('map/SET_FLIGHT_AREAS', store.getters['activations/mapAreas'], { root: true });
      }
      store.dispatch(
        'map/SET_ACTIVATION_SELECTED',
        store.getters['activations/activationArea'],
        { root: true },
      );
    },
  );
  store.watch(
    (state, getters) => getters['traces/mapTracesCenters'],
    (newValue) => {
      store.dispatch('map/SET_TRACES_CENTERS', newValue, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['traces/mapTracesLines'],
    (newValue) => {
      store.dispatch('map/SET_TRACES_LINES', newValue, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['traces/traceHover'],
    (newValue) => {
      store.dispatch('map/SET_TRACE_HOVER_OR_SELECTED', newValue?.id, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['traces/traceSelected'],
    (newValue) => {
      store.dispatch('map/SET_TRACE_HOVER_OR_SELECTED', newValue?.id, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['traces/mapTracesDashLine'],
    (newValue) => {
      store.dispatch('map/SET_TRACES_DASH_LINES', newValue, { root: true });
    },
  );
  store.watch(
    (state, getters) => getters['traces/selectedMapTracesDashLine'],
    (newValue) => {
      store.dispatch('map/SET_TRACES_DASH_LINES_SELECTED', newValue, { root: true });
    },
  );
  store.watch(
    (state) => [state.map.isMapDataLoaded, state.structures.structuresAIPGeometries],
    ([isMapDataLoaded, structuresAIPGeometries]) => {
      if (isMapDataLoaded) {
        store.dispatch(
          'map/SET_STRUCTURES_AIP_GEOMETRIES', structuresAIPGeometries, { root: true },
        );
      }
    },
  );
};

export default mapDataWatcherPlugin;
