import variables from '@/styles/variables.scss';

export const DEFAULT_BOUNDING_BOX = [[-1, 43], [4, 51]];
const minZoomAreaLayer = 10;

const matchingColor = [
  'case',
  ['all',
    ['!=', ['get', 'flight'], null],
    ['==', ['get', 'en_route', ['get', 'flight']], true],
  ],
  variables.flightHighlightedBackgroundColor,
  ['any',
    ['==', ['get', 'is_archived'], true],
    ['==', ['get', 'is_closed'], true],
  ],
  variables.flightArchivedBackgroundColor,
  ['any',
    ['==', ['get', 'status'], 'submitted'],
    ['==', ['get', 'status'], 'awaiting_validation'],
  ],
  variables.flightSubmittedBackgroundColor,
  // Only approval objects have a flight property
  ['all',
    ['==', ['get', 'is_authority'], null],
    ['==', ['get', 'status'], 'incomplete'],
  ],
  variables.flightIncompleteDronistBackgroundColor,
  ['==', ['get', 'is_supervision'], true],
  variables.processingConstraintBackgroundColor,
  ['==', ['get', 'status'], 'incomplete'],
  variables.flightIncompleteAuthorityBackgroundColor,
  ['==', ['get', 'status'], 'accepted'],
  variables.flightAcceptedBackgroundColor,
  ['==', ['get', 'status'], 'refused'],
  variables.flightRefusedBackgroundColor,
  ['==', ['get', 'status'], 'reserves'],
  variables.flightReservesBackgroundColor,
  ['==', ['get', 'status'], 'action_needed'],
  variables.flightReservesBackgroundColor,
  ['==', ['get', 'status'], 'pending'],
  variables.flightPendingBackgroundColor,
  ['==', ['get', 'status'], 'checked'],
  variables.checkedConstraintBackgroundColor,
  ['==', ['get', 'status'], 'planned'],
  variables.flightPlannedBackgroundColor,
  ['==', ['get', 'status'], 'done'],
  variables.flightDoneBackgroundColor,
  ['==', ['get', 'status'], 'clear'],
  variables.clearConstraintBackgroundColor,
  ['==', ['get', 'status'], 'processing'],
  variables.processingConstraintBackgroundColor,
  ['==', ['get', 'status'], 'confirmed'],
  variables.confirmedFlightBackgroundColor,
  variables.flightDefaultBackgroundColor,
];

const matchingTrackerIcon = [
  'case',
  ['==', ['get', 'has_livestream'], true],
  'drone_livestream',
  'drone',
];

const matchingPlaneIcon = [
  'case',
  ['==', ['get', 'warning_position'], 'red'],
  'red_plane',
  ['==', ['get', 'warning_position'], 'orange'],
  'orange_plane',
  'green_plane',
];

const matchingTraceColor = [
  'case',
  ['==', ['get', 'is_active'], true],
  variables.flightHighlightedBackgroundColor,
  ['get', 'color'],
];

export const CUSTOM_LAYER_AREA_STYLE_IDENTIFIER = 'CUSTOM_LAYER_AREA_STYLE';
export const CUSTOM_LAYER_OUTLINE_STYLE_IDENTIFIER = 'CUSTOM_LAYER_OUTLINE_STYLE';
export const LABELS_POLYGON_STYLE_IDENTIFIER = 'LABELS_POLYGON_STYLE';
export const LABELS_CENTER_POLYGON_STYLE_IDENTIFIER = 'LABELS_CENTER_POLYGON_STYLE';

export const DEFAULT_POLYGON_STYLE = {
  mapboxStyleOptions: {
    type: 'fill',
    paint: {
      'fill-color': 'blue',
      'fill-outline-color': '#058d7a',
      'fill-opacity': 0.3,
    },
  },
  persistent: false,
};

export const DEFAULT_RASTER_STYLE = {
  sourceOptions: {
    minzoom: 5,
    maxzoom: 18,
    type: 'raster',
    bounds: [-6, 38, 12, 52],
  },
  mapboxStyleOptions: { layout: {} },
  persistent: false,
};

export const LAYER_STYLES = {
  LABELS_POLYGON_STYLE: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'line',
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
      },
    },
    persistent: false,
  },
  LABELS_CENTER_POLYGON_STYLE: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['center'],
        'text-radial-offset': 0.5,
        'text-justify': 'center',
      },
    },
    persistent: false,
  },
  GeoportailOACI: {
    sourceOptions: {
      minzoom: 5,
      maxzoom: 18,
      type: 'raster',
      bounds: [-6, 38, 12, 52],
    },
    mapboxStyleOptions: { layout: { visibility: 'visible' } },
    persistent: false,
  },
  CTR_AREAS_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': '#058d7a',
        'fill-outline-color': '#058d7a',
        'fill-opacity': 0.3,
      },
    },
    persistent: false,
  },
  CTR_AREAS_LAYER_ONLY_FOR_SUBSCRIBERS: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': '#f16522',
        'fill-outline-color': '#f16522',
        'fill-opacity': 0.3,
      },
    },
    persistent: false,
  },
  CONSTRAINT_SELECTED_LABEL_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'line',
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
      },
    },
    persistent: false,
  },
  CONSTRAINT_HOVER_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': 'orange',
        'fill-outline-color': 'white',
        'fill-opacity': 0.5,
      },
    },
    persistent: false,
  },
  CONSTRAINT_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': [
          'case',
          ['all',
            ['!=', ['get', 'structure_code_type'], 'AD'],
            ['!=', ['get', 'structure_code_type'], 'HP'],
            ['!=', ['get', 'structure_code_type'], 'ULM'],
          ],
          [
            'case',
            ['==', ['get', 'is_extension_area'], true],
            '#ff7f27',
            'red',
          ],
          ['==', ['get', 'height_reference'], 'ground'], '#ed1c24',
          ['<', [
            'abs',
            ['-',
              ['-',
                ['get', 'altitude_min'],
                ['get', 'airport_reference_altitude'],
              ],
              50,
            ]],
          0.1],
          '#ff7f27',
          ['<', [
            'abs',
            ['-',
              ['-',
                ['get', 'altitude_min'],
                ['get', 'airport_reference_altitude'],
              ],
              30,
            ],
          ],
          0.1],
          '#ff7f27',
          ['<', [
            'abs',
            ['-',
              ['-',
                ['get', 'altitude_min'],
                ['get', 'airport_reference_altitude'],
              ],
              100,
            ],
          ],
          0.1],
          '#fef100',
          ['<', [
            'abs',
            ['-',
              ['-',
                ['get', 'altitude_min'],
                ['get', 'airport_reference_altitude'],
              ],
              60,
            ],
          ],
          0.1],
          '#fec80d',
          'black',
        ],
        'fill-outline-color': 'black',
        'fill-opacity': 0.4,
      },
    },
    persistent: false,
  },
  NOTAM_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': 'red',
        'fill-outline-color': 'black',
        'fill-opacity': 0.4,
      },
    },
    persistent: false,
  },
  PSA_OUTLINES_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': '#ff0000',
        'line-opacity': 0.5,
      },
    },
    persistent: false,
  },
  PSA_ELEVATIONS_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      layout: {
        'symbol-placement': 'line',
        'text-field': [
          'case',
          ['==', ['typeof', ['get', 'altitude']], 'number'], ['get', 'altitude'],
          '',
        ],
      },
    },
    persistent: false,
  },
  FLIGHT_AREAS_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      minzoom: minZoomAreaLayer,
      paint: {
        'fill-color': matchingColor,
        'fill-outline-color': matchingColor,
        'fill-opacity': 0.3,
      },
    },
    persistent: false,
  },
  FLIGHT_AREAS_HOVER_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': matchingColor,
        'fill-outline-color': matchingColor,
        'fill-opacity': 0.5,
      },
    },
    persistent: true,
  },
  FLIGHT_AREAS_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': matchingColor,
        'fill-outline-color': matchingColor,
        'fill-opacity': 0.3,
      },
    },
    persistent: true,
  },
  FLIGHT_AREAS_SELECTED_LABEL_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-color': '#fff',
        'text-halo-color': '#000',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'line',
        'text-field': ['get', 'name'],
        'text-size': 18,
        'text-font': ['Roboto Black', 'Arial Unicode MS Bold'],
        'text-letter-spacing': 0.05,
      },
    },
    persistent: true,
  },
  FLIGHT_AREAS_SELECTED_HEIGHT_LABEL_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      minzoom: minZoomAreaLayer,
      paint: {
        'text-color': '#fff',
        'text-halo-color': '#000',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'height'],
        'text-size': 18,
        'text-font': ['Roboto Black', 'Arial Unicode MS Bold'],
        'text-letter-spacing': 0.05,
      },
    },
    persistent: true,
  },
  FLIGHT_AREAS_OUTLINE_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      minzoom: minZoomAreaLayer,
      paint: {
        'line-color': matchingColor,
        'line-width': 2.5,
      },
    },
    persistent: false,
  },
  FLIGHT_AREAS_OUTLINE_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      minzoom: minZoomAreaLayer,
      paint: {
        'line-color': '#fff',
        'line-width': 4.5,
      },
    },
    persistent: true,
  },
  FLIGHT_AREAS_OUTLINE_HOVER_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      minzoom: minZoomAreaLayer,
      paint: {
        'line-color': matchingColor,
        'line-width': 4.5,
      },
    },
    persistent: true,
  },
  FLIGHT_CENTERS_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        // make circles larger as the user zooms from z12 to z22
        'circle-radius': {
          base: 15,
          stops: [[10, 15], [12, 17], [22, 15]],
        },
        // https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
        'circle-color': matchingColor,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'black',
      },
    },
    persistent: false,
  },
  FLIGHT_CENTERS_HOVER_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        'circle-radius': {
          base: 20,
          stops: [[15, 20], [17, 22], [27, 20]],
        },
        'circle-color': matchingColor,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'black',
      },
    },
    persistent: false,
  },
  FLIGHT_CENTERS_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        'circle-radius': {
          base: 15,
          stops: [[10, 20], [12, 25], [22, 20]],
        },
        'circle-color': 'white',
        'circle-stroke-width': 3,
        'circle-stroke-color': matchingColor,
      },
    },
    persistent: true,
  },
  FLIGHT_AREA_SELECTED_OUTLINE_HOVER: {
    mapboxStyleOptions: {
      type: 'line',
      minzoom: minZoomAreaLayer,
      paint: {
        'line-color': '#fff',
        'line-width': 4.5,
        'line-offset': 2,
      },
    },
    persistent: true,
  },
  FLIGHT_AREA_SELECTED_HOVER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': matchingColor,
        'fill-outline-color': matchingColor,
        'fill-opacity': 0.5,
      },
    },
    persistent: true,
  },
  FLIGHT_AREA_MEASUREMENTS_INFO_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
        'text-halo-blur': 0.2,
      },
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'measurements'],
        'text-justify': 'left',
        'text-size': 14,
      },
    },
    persistent: true,
  },
  APPROVAL_AREAS_SELECTED: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': matchingColor,
        'fill-outline-color': matchingColor,
        'fill-opacity': 0.5,
      },
    },
    persistent: true,
  },
  APPROVAL_AREAS_SELECTED_OUTLINE: {
    mapboxStyleOptions: {
      type: 'line',
      minzoom: minZoomAreaLayer,
      paint: {
        'line-color': 'white',
        'line-width': 4.5,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      minzoom: minZoomAreaLayer,
      paint: {
        'fill-color': '#ed1c24',
        'fill-outline-color': '#ed1c24',
        'fill-opacity': 0.1,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_OUTLINE_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': '#ed1c24',
        'line-width': 4.5,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': '#ed1c24',
        'line-width': 3,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_ZONE_RADIUS_LINES_CENTERS_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-color': '#fff',
        'text-halo-color': '#000',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'point',
        'text-field': ['get', 'name'],
        'text-size': 18,
        'text-font': ['Roboto Black', 'Arial Unicode MS Bold'],
        'text-letter-spacing': 0.05,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      minzoom: minZoomAreaLayer,
      paint: {
        'fill-color': variables.flightExclusionForbiddenZone,
        'fill-outline-color': variables.flightExclusionForbiddenZone,
        'fill-opacity': 0.3,
      },
    },
    persistent: true,
  },
  FLIGHT_EXCLUSION_FORBIDDEN_ZONE_OUTLINE_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': variables.flightExclusionForbiddenZone,
        'line-width': 4.5,
      },
    },
    persistent: true,
  },
  DRONE_TRACKING_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'icon-image': matchingTrackerIcon,
        'icon-size': 0.7,
        'icon-allow-overlap': true,
        'text-field': ['get', 'altitude_map'],
        'text-size': 12,
        'text-anchor': 'top',
        'text-radial-offset': 2,
        'text-justify': 'auto',
      },
    },
  },
  DRONE_TRACKING_LAYER_DIRECTION: {
    mapboxStyleOptions: {
      type: 'symbol',
      layout: {
        'icon-image': 'speed-vector',
        'icon-size': 0.5,
        'icon-allow-overlap': true,
        'icon-rotate': ['get', 'angle'],
        'icon-anchor': 'bottom',
      },
    },
  },
  PLANE_TRACKING_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      layout: {
        'icon-image': matchingPlaneIcon,
        'icon-rotate': ['get', 'angle'],
        'icon-size': 0.125,
        'icon-allow-overlap': true,
        'text-field': ['get', 'altitude_map'],
        'text-size': 12,
        'text-anchor': 'top',
        'text-radial-offset': 2.5,
        'text-justify': 'auto',
      },
      paint: {
        'icon-color': ['get', 'warning_position'],
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
    },
  },
  DRONE_TRACKING_TRACE_LINES_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': matchingTraceColor,
        'line-width': 2,
      },
      filter: ['==', ['geometry-type'], 'LineString'],
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_LINES_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': matchingTraceColor,
        'line-width': 6,
      },
      filter: ['==', ['geometry-type'], 'LineString'],
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_POINTS_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        'circle-radius': {
          base: 5,
          stops: [[6, 5], [8, 5]],
        },
        'circle-stroke-width': 1,
        'circle-stroke-color': 'white',
      },
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_CENTERS_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        'circle-radius': {
          base: 5,
          stops: [[6, 12], [8, 18]],
        },
        'circle-color': matchingTraceColor,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'black',
      },
      filter: ['==', ['geometry-type'], 'Point'],
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_CENTERS_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'circle',
      paint: {
        'circle-radius': {
          base: 8,
          stops: [[8, 18], [11, 22]],
        },
        'circle-color': matchingTraceColor,
        'circle-stroke-width': 2,
        'circle-stroke-color': 'black',
      },
      filter: ['==', ['geometry-type'], 'Point'],
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_LABELS_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'text-size': 16,
        'symbol-placement': 'point',
        'text-field': ['get', 'id'],
        'text-allow-overlap': true,
      },
      filter: ['==', ['geometry-type'], 'Point'],
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_DASH_LINES_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': matchingTraceColor,
        'line-width': 2,
        'line-dasharray': [2, 2],
      },
    },
    persistent: false,
  },
  DRONE_TRACKING_TRACE_DASH_LINES_SELECTED_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': matchingTraceColor,
        'line-width': 6,
        'line-dasharray': [1, 1],
      },
    },
    persistent: false,
  },
  CUSTOM_LAYER_AREA_STYLE: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': [
          'case',
          ['==', ['typeof', ['get', 'fillColor']], 'string'], ['get', 'fillColor'],
          'rgba(0, 0, 255, 0.2)',
        ],
      },
    },
    persistent: false,
  },
  CUSTOM_LAYER_OUTLINE_STYLE: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['typeof', ['get', 'strokeColor']], 'string'], ['get', 'strokeColor'],
          'rgba(0, 140, 110, 0.7)',
        ],
        'line-width': 4,
      },
    },
    persistent: false,
  },
  AUTHORITY_AIP_AREAS_LAYER: {
    mapboxStyleOptions: {
      type: 'fill',
      paint: {
        'fill-color': variables.primary,
        'fill-opacity': 0.1,
      },
    },
    persistent: false,
  },
  AUTHORITY_AIP_AREAS_OUTLINE_LAYER: {
    mapboxStyleOptions: {
      type: 'line',
      paint: {
        'line-color': variables.primary,
        'line-width': 2.5,
      },
    },
    persistent: false,
  },
  AUTHORITY_AIP_AREAS_LABEL_LAYER: {
    mapboxStyleOptions: {
      type: 'symbol',
      paint: {
        'text-halo-color': '#ffffff',
        'text-halo-width': 1.5,
        'text-halo-blur': 0.5,
      },
      layout: {
        'symbol-placement': 'line',
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
      },
    },
    persistent: false,
  },
};

export const POPUPS_STYLES = {
  DEFAULT: {
    popup: {
      offset: [0, -15],
      closeButton: false,
      closeOnClick: false,
    },
  },
  DRONE_TRACKING_LAYER: {
    popup: {
      offset: [0, -25],
      closeButton: false,
      closeOnClick: false,
      className: 'tracking',
    },
  },
  DRONE_TRACKING_TRACE_LINES_LAYER: {
    popup: {
      closeButton: false,
      closeOnClick: false,
      className: 'tracking-trace',
    },
  },
  DRONE_TRACKING_TRACE_CENTERS_LAYER: {
    popup: {
      closeButton: false,
      closeOnClick: false,
      className: 'tracking-trace',
    },
  },
  FLIGHT_CENTERS_LAYER: {
    popup: {
      closeButton: false,
      closeOnClick: false,
      className: 'flights',
      maxWidth: '360px',
    },
  },
  CONSTRAINTS_POINT_POPUP: {
    popup: {
      closeButton: true,
      closeOnClick: false,
      className: 'constraints',
      maxWidth: '520px',
    },
    marker: { color: variables.primary },
  },
  CONSTRAINTS_CLIENT_STRUCTURE_POPUP: {
    popup: {
      closeButton: true,
      closeOnClick: false,
      className: 'client-constraints',
      maxWidth: '520px',
    },
  },
};
