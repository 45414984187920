<template>
  <div class="supervision-listing">
    <v-data-table
      :headers="headers"
      :items="supervisions"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :hide-default-footer="smallDisplay"
      :class="{bigDisplay: bigDisplay, smallDisplay: !bigDisplay, transparent: true}"
      :footer-props="{
        'items-per-page-text': texts.itemsPerPage,
        'items-per-page-all-text': texts.all,
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :loading-text="texts.loading"
      :no-data-text="texts.noApprovalFound"
      item-key="id"
      :no-results-text="texts.noApprovalFound"
      dark
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      />
      <template
        slot="item"
        slot-scope="props"
      >
        <tr
          v-if="props && props.item"
          class="cursor-pointer"
          @click="centerOnArea(props.item.geometry)"
          @mouseenter="idHover = props.item.id"
          @mouseleave="idHover = null"
        >
          <!-- Identifier -->
          <td v-if="headers.find(e => e.value == 'identifier')">
            <div class="text-center font-weight-medium">
              {{ props.item.identifier }}
            </div>
          </td>
          <!-- Period -->
          <td v-if="headers.find(e => e.value == 'period')">
            {{ props.item.flight.date_start | dateShort }}
            - {{ props.item.flight.date_end | dateShort }}
          </td>
          <!-- Hour -->
          <td v-if="headers.find(e => e.value == 'hour')">
            {{ props.item.flight.time_start | hour }} - {{ props.item.flight.time_end | hour }}
          </td>
          <!-- Height -->
          <td v-if="headers.find(e => e.value == 'flying_height')">
            {{ props.item.max_flying_height | meter }}
          </td>
          <!-- In/off sight -->
          <td
            v-if="headers.find(e => e.value == 'pilot_in_sight')"
            class="text-lowercase"
          >
            <span v-if="props.item.flight.pilot_in_sight">
              {{ texts.inSight }}
            </span>
            <span
              v-else
              class="error--text font-weight-bold"
            >
              {{ texts.offSight }}
            </span>
          </td>
          <!-- Structure name -->
          <td v-if="headers.find(e => e.value == 'structure_name')">
            {{ props.item.structure_name }}
          </td>
        </tr>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        <span v-translate="
          {pageStart: items.pageStart, pageStop: items.pageStop, length: items.itemsLength}
        ">
          %{ pageStart } - %{ pageStop } of %{ length }
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { APPLICATION_DISPLAY } from '@/store/application';
import { ZOOM_TO_AREA_NS } from '@/store/map';
import { SET_SUPERVISION_HOVER_NS, SET_PAGINATION_NS } from '@/store/supervisions';

import makeSupervisionListingHeaders from '@/components/Supervisions/SupervisionListingHeaders';

export default {
  name: 'SupervisionListing',
  data() {
    return {
      smallHeaders: [],
      mediumHeaders: [],
      bigHeaders: [],
    };
  },
  computed: {
    texts() {
      return {
        itemsPerPage: this.$gettext('Lines per page:'),
        all: this.$gettext('All'),
        loading: this.$gettext('Loading approvals...'),
        noApprovalFound: this.$gettext('No approval found.'),
        inSight: this.$gettext('In sight'),
        offSight: this.$gettext('Off sight'),
      };
    },
    display() {
      return this.$store.state.application.display;
    },
    bigDisplay() {
      return this.display === APPLICATION_DISPLAY.BIG;
    },
    mediumDisplay() {
      return this.display === APPLICATION_DISPLAY.MEDIUM;
    },
    smallDisplay() {
      return this.display === APPLICATION_DISPLAY.SMALL;
    },
    headers() {
      if (this.bigDisplay) return this.bigHeaders;
      if (this.smallDisplay) return this.smallHeaders;
      return this.mediumHeaders;
    },
    loading() {
      return this.$store.state.supervisions.tableItemsLoading;
    },
    supervisions() {
      return !this.loading ? this.$store.getters['supervisions/tableItems'] : [];
    },
    itemsLength() {
      return this.$store.state.supervisions.itemsLength;
    },
    loadingDetails() {
      return this.$store.state.supervisions.supervisionDetailsLoading;
    },
    options: {
      get() {
        return this.$store.state.supervisions.pagination;
      },
      set(newValue) {
        this.$store.dispatch(SET_PAGINATION_NS, newValue);
      },
    },
    idHover: {
      get() {
        return this.$store.state.map.featureIdHovered.flight;
      },
      set(newValue) {
        this.$store.dispatch(SET_SUPERVISION_HOVER_NS, newValue);
      },
    },
  },
  created() {
    this.setHeaders();
  },
  methods: {
    setHeaders() {
      const headers = makeSupervisionListingHeaders(this.$gettext);
      this.smallHeaders = headers.smallHeaders;
      this.mediumHeaders = headers.mediumHeaders;
      this.bigHeaders = headers.bigHeaders;
    },
    centerOnArea(area) {
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.supervision-listing ::v-deep .v-data-table__wrapper>table> {
  tbody>tr,
  tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: white;
    color: $color-secondary;
  }
  tbody>tr:not(.v-datatable__expand-row) {
    &:not(:first-child) td {
      border-top: 15px solid $color-secondary;
      height: 60px;
    }
    &:first-child td {
      height: 45px;
    }
  }
  tbody>tr:hover:not(.highlighted):not(.selected) {
    background-color: lighten($color: $color-info, $amount: 50);
  }
}

</style>
