export default function makeSupervisionListingHeaders(gettext) {
  const identifierHeader = {
    text: gettext('N°'),
    value: 'identifier',
    class: 'pl-6',
    sortable: true,
  };
  const periodHeader = {
    text: gettext('Period'),
    value: 'period',
    sortable: false,
  };
  const hourHeader = {
    text: gettext('Hour'),
    value: 'hour',
    sortable: false,
  };
  const maxHeightHeader = {
    text: gettext('Max flying height'),
    value: 'flying_height',
    sortable: true,
  };
  const inSightHeader = {
    text: gettext('In sight'),
    value: 'pilot_in_sight',
    sortable: true,
  };
  const structureHeader = {
    text: gettext('Structure'),
    value: 'structure_name',
    sortable: true,
  };

  return {
    smallHeaders: [
      { ...identifierHeader, width: '100%' },
    ],
    mediumHeaders: [
      { ...identifierHeader, width: '20%' },
      { ...periodHeader, width: '25%' },
      { ...hourHeader, width: '20%' },
      { ...maxHeightHeader, width: '25%' },
      { ...inSightHeader, width: '10%' },
    ],
    bigHeaders: [
      { ...identifierHeader, width: '15%' },
      { ...periodHeader, width: '20%' },
      { ...hourHeader, width: '15%' },
      { ...maxHeightHeader, width: '20%' },
      { ...inSightHeader, width: '10%' },
      { ...structureHeader, width: '20%' },
    ],
  };
}
